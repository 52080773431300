.plan-panel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  min-height: 100vh;
  background-color: #fcfcfe; /* bg-gray-100 */
  padding: 0.75rem; /* p-8 */
}

.plan-panel-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  padding: 0.75rem; /* p-8 */
  background-color: #fcfcfe;
}

.svg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
}

.plan-header {
  margin-bottom: 2rem; /* mb-8 */
  width: 100%;
}

.plan-header h1 {
  font-size: 1.2rem; /* text-2xl */
  font-weight: bold;
  color: #6d28d9;
}

.card {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 0.3rem; /* gap-x-2 */
  width: 100%;
}

.card-col-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

/* CSS class to reduce the size of the SVG and add transparent padding */
.icon-container {
  width: 32px; /* Width of the container */
  height: 32px; /* Height of the container */
  padding: 5px; /* Add padding for transparent border */
}
