/* src/App.css */

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.title {
  flex-shrink: 0;
  padding: 1em;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
}

.info-strip {
  flex-shrink: 0;
  padding: 1em;
  background-color: #e8e8e8;
  border-bottom: 1px solid #ddd;
}

.map-section {
  flex-grow: 1;
  position: relative;
  border-bottom: 1px solid #ddd;
}

.collapsible-panel {
  flex-shrink: 0;
  padding: 1em;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.markdown-section {
  flex-grow: 1;
  padding: 1em;
  background-color: #f8f8f8;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home-container button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.flex-grow {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Ensure frozen components have higher z-index */
.map-title {
  position: relative;
  z-index: 10; /* Adjust to ensure it's above the PlanPanel components */
}

.information-strip {
  position: relative;
  z-index: 10; /* Adjust to ensure it's above the PlanPanel components */
}

.info-panel {
  position: relative;
  z-index: 10; /* Adjust to ensure it's above the PlanPanel components */
}
