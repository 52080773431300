.card-content {
    background-color: #e5eaf9;
    padding: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}

.card-content-col-2 {
    border: 1px solid #b7bff8;
    border-radius: 8px;
    overflow: hidden; /* Prevent overflow */
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}

.card {
    min-height: 70px;
    margin-bottom: 15px;
    display: flex;
}

.card-col-1 {
    width: 65px;
}

.card-content h3 {
    font-size: 1rem; /* Example for heading */
}

.card-content p {
    font-size: 0.8rem; /* Example for paragraph */
}

.place-display {
    display: flex;
    align-items: center; /* Horizontally align items */
  }
  
  .label {
    margin: 0;
    padding-right: 10px;
  }
  
  .category {
    font-size: 0.7rem;
    color: #fff;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #7c3aed;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  