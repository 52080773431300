.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: small;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.footer .text-blue-400 {
  color: #60a5fa;
  text-decoration: underline; /* Add underline */
}

.footer .hover\:text-blue-600:hover {
  color: #2563eb;
}

.footer .space-x-4 > *:not(:last-child) {
  margin-right: 1rem;
}
