.marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #fbbf24 30%,
    #f87171 100%
  );
  border: 1px solid #c7d2fe;
}

.marker i {
  font-size: 12px;
  color: #fafafa;
}

/* src/components/MapStyles.css */
.circle-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}


.marker-number {
  font-size: 14px;
  font-weight: bold;
  color: #000000; /* Specify the desired color here */
}

/* New marker variants */
.marker-restaurant {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #f97316 30%,
    #e11d48 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-restaurant i {
  font-size: 14px;
  color: #ffffff;
}

.marker-outdoors {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #5eead4 30%,
    #0d9488 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-outdoors i {
  font-size: 10px;
  color: #ffffff;
}


.marker-transport {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #60a5fa 30%,
    #0284c7 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-transport i {
  font-size: 10px;
  color: #ffffff;
}

.marker-site {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #f59e0b 30%,
    #facc15 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-site i {
  font-size: 10px;
  color: #ffffff;
}

.marker-gold {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #f59e0b 30%,
    #facc15 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-gold i {
  font-size: 10px;
  color: #ffffff;
}

.marker-purple {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #8b5cf6 30%,
    #a78bfa 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-purple i {
  font-size: 10px;
  color: #000;
}

.marker-flag {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #f1f5f9 30%,
    #e2e8f0 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-flag i {
  font-size: 10px;
  color: #be123c;
}

.marker-blue {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #3b82f6 30%,
    #1d4ed8 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-blue i {
  font-size: 10px;
  color: #ffffff;
}

.marker-green {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #22c55e 30%,
    #059669 100%
  );
  border: 1px solid #c7d2fe;
}

.marker-green i {
  font-size: 10px;
  color: #ffffff;
}