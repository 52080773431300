/* src/components/InfoPanel.css */
.infopanel {
  background-color: #f4f4fb;
  padding: 16px;
  height: 0;
  overflow: hidden;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: height 0.3s ease;
  position: relative;
}

.infopanel.expanded {
  height: 270px; /* Adjusted height to accommodate new links */
}

.infopanel.minimized {
  height: 40px;
}

.infopanel-image-section {
  max-width: 120px;
  margin-left: 16px;
}

.infopanel-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}

.infopanel-info-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.infopanel-content {
  display: flex;
  text-align: left;
  justify-content: flex-start;
}

.infopanel-category {
  font-size: 0.8rem;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #7c3aed;
}

.infopanel-title {
  flex-grow: 1;
  text-align: left;
  font-weight: bold;
  font-size: 0.9rem;
}

.infopanel-shortdesc {
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;
}


.infopanel-hours {
  font-size: 0.8rem;
  color: #7c3aed;
}

.infopanel-description {
  margin-top: 8px;
  font-size: 0.9rem;
  font-style: italic;
  color: #666;
}

.infopanel-links {
  margin-top: 8px;
}

.infopanel-link {
  font-size: 0.75rem;
  color: #666;
}

.infopanel-link a {
  color: #007bff;
  text-decoration: none;
}

.infopanel-link a:hover {
  text-decoration: underline;
}

.infopanel-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.infopanel-audio {
  height: 30px;
}
