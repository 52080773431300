.flash-tips {
    background-color: #d5dffc;
    padding: 2px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 4px;
}

.flash-tips-icon {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.flash-tips-text {
    font-size: 0.72rem; /* Example for paragraph */
}
